
import './App.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavHeader from "./NavHeader";
import Usage from "./Usage";
import Agreements from "./Agreements";
import Products from './Products';
import StandardUnitRates from './StandardUnitRates';
import UsageDay from './UsageDay';
import Settings from './Settings';
import Login from './Login/Login';

function App() {
  const [settings, setSettings] = useState();
  
  const getSettings = () => {
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/settings.json`)
      .then(({ data }) => {
        setSettings(data.settings);
        if ((data.settings['apikey'].length < 5 || data.settings['accountid'].length < 5 ) && window.location.pathname !== '/settings') {
          window.location.href = '/settings';
        }

      })
      .catch((error) => console.error(error));
  }

  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        if (window.location.pathname !== '/login') {
          window.location = "/login";
        }
      }
      return Promise.reject(error)
    }
  );

  useEffect(() => {
    getSettings();  
  }, []);


  if (!JSON.parse(localStorage.getItem('user'))) {
    return <Login />;
  }  



  return (
    <div className="font-body bg-gray-100 min-h-screen flex flex-col text-gray-900 pb-10">
      <Helmet
        titleTemplate={`%s`}
        defaultTitle={process.env.REACT_APP_NAME}
      />
      <NavHeader />

      <Router>
        <Routes>
          <Route path="/"           element={<Usage settings={settings} />} />
          <Route path="/usage/day" element={<UsageDay settings={settings} />} />
          <Route path="/usage/day/:_startDate" element={<UsageDay settings={settings} />} />
          <Route path="/agreements" element={<Agreements settings={settings} />} />
          <Route path="/products" element={<Products settings={settings} />} />
          <Route path="/standard_unit_rates" element={<StandardUnitRates settings={settings} />} />
          <Route path="/settings" element={<Settings settings={settings} setSettings={setSettings} />} />
        </Routes>
        <div className="flex flex-grow mt-4">
        </div>
      </Router>
    </div>
  );
}

export default App;
