import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "./components/Helpers/LoadingSpinner";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import Table from "./components/Table";
import { Spinner } from "react-bootstrap";

function StandardUnitRates() {
  const [unitRates, setUnitRates] = useState();
  const [syncStarted, setSyncStarted] = useState(false);


  const getUnitRates = () => {
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/standard_unit_rates.json`)
      .then(({ data }) => {
        setUnitRates(data.standard_unit_rates);
      })
      .catch((error) => console.error(error));
  }

  const syncUnitRates = (all) => {
    setSyncStarted(true)
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/standard_unit_rates/sync.json${all ? '?all=true' : ''}`)
      .then(({ data }) => {
        setUnitRates(data.standard_unit_rates);
        setSyncStarted(false)
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getUnitRates();
  }, []);

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Tariff Code",
        field: "tariff",
        sortable: true,
        minWidth: 150,
        maxWidth: 300,
      }, {
        headerName: "Value EX VAT",
        field: "value_exc_vat",
        sortable: false,
      }, {
        headerName: "Value INC VAT",
        width: 250,
        field: "value_inc_vat",
      }, {
        headerName: "Valid From",
        field: "valid_from",
        cellRenderer: function (params) {
          return (<Moment format={`HH:mm Do MMM YYYY`} >{params.value}</Moment>)
        }
      }, {
        headerName: "Valid To",
        field: "valid_to",
        sort: "desc",
        cellRenderer: function (params) {
          return (<Moment format={`HH:mm Do MMM YYYY`} >{params.value}</Moment>)
        }
      }
    ]
  }

  if (!unitRates) { return (<LoadingSpinner />) }

  return (
    <>
        <Table
          gridOptions={gridOptions}
          rowData={unitRates}
        />
      <Button disabled={syncStarted} onClick={() => syncUnitRates()}>Sync{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button><Button disabled={syncStarted} onClick={() => syncUnitRates(true)}>Sync All{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button>
    </>
  );
}

export default StandardUnitRates;