import React, { useState } from "react";
import axios from "axios";
import { Button, Row, Col } from "react-bootstrap"
import toast from "react-hot-toast";

function LoginForm({ setHasForgotPassword }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalidCredentials, setInvalidCredentials] = useState('');

    sessionStorage.clear();
    localStorage.clear();

    function validateEmail(email) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    const login = () => {
        let loginPromise = axios
            .post(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/users/sign_in.json`, {
                user: {
                    email: email,
                    password: password,
                }
            })
            .then(({ data }) => {
                localStorage.setItem("user", JSON.stringify(data.user));
                window.location = "/";
            })
            .catch((error) => {
                setInvalidCredentials('Invalid Credentials');
                return Promise.error(error)
            });
        toast.promise(loginPromise, {
            loading: "Logon....",
            success: "Login Sucessful",
            error: "Login Failed.",
        });

    };

    const handleKeyDownPassword = (event) => {
        if (event.key === 'Enter') {
            login();
        }
    }

    return (
        <>
            <Row>
                <div className="rounded-md">
                    <div>
                        <input
                            aria-label="Email"
                            name="email"
                            type="text"
                            required=""
                            className="un"
                            placeholder="E-Mail Address"
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) => invalidCredentials ? setInvalidCredentials('') : null}
                            value={email}
                        />
                    </div>
                    <div>
                        <input
                            aria-label="Password"
                            name="password"
                            type="password"
                            required=""
                            className="pass"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={(e) => invalidCredentials ? setInvalidCredentials('') : null}
                            onKeyDown={(e) => handleKeyDownPassword(e)}
                            value={password}
                        />
                    </div>
                </div>
                {invalidCredentials
                    ? <div className="bad-password"><strong>{invalidCredentials}</strong></div>
                    : <div className="nothing"><strong>.</strong></div>
                }
            </Row>
            <Row>
                <Col><Button size="sm" className="left" variant="secondary" onClick={() => setHasForgotPassword(true)}>Forgot Password?</Button></Col>
                <Col><Button size="sm" className="right"
                    variant="primary"
                    disabled={!(validateEmail(email) && password.length > 6)}
                    onClick={() => login()}
                >Login</Button></Col>
            </Row>
        </>
    );
}

export default LoginForm;
