import React from "react";
import { Spinner } from "react-bootstrap";

import "./LoadingSpinner.css";


function LoadingSpinner() {

  return (
    <>
    <div className="spinner-box">
      <div align="center">
        <p className="sign" align="center">Octotrack</p>
    <Spinner animation="border" variant="light" >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    </div>
    <br/>
    <h2 align="center" className="loading-white">One moment</h2>
    <p align="center" className="loading-white">Loading Content</p>
    </div>
    </>
  )

}

export default LoadingSpinner;