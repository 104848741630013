import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "./components/Helpers/LoadingSpinner";
import { Button } from "react-bootstrap";
import Table from "./components/Table";
import { Spinner } from "react-bootstrap";

function Products() {
  const [products, setProducts] = useState();
  const [syncStarted, setSyncStarted] = useState(false);


  const getProducts = () => {
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/products.json`)
      .then(({ data }) => {
        setProducts(data.products);
      })
      .catch((error) => console.error(error));
  }

  const syncProducts = (all) => {
    setSyncStarted(true)
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/products/sync.json${all ? '?all=true' : ''}`)
      .then(({ data }) => {
        setProducts(data.products);
        setSyncStarted(false)
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getProducts();
  }, []);

  if (!products) { return (<LoadingSpinner />) }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Product Code",
        field: "code",
        sortable: true,
        minWidth: 150,
        maxWidth: 300,
      }, {
        headerName: "Standing Charge EX VAT",
        field: "standing_charge_exc_vat",
      }, {
        headerName: "Standing Charge INC VAT",
        field: "standing_charge_inc_vat",
      }
    ]
  }

  return (
    <>
    <Table
          gridOptions={gridOptions}
          rowData={products}
        />
      <Button disabled={syncStarted} onClick={() => syncProducts()}>Sync{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button>
    </>
  );
}

export default Products;