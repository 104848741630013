import { Modal } from 'react-bootstrap';
import axios from "axios";
import { useState } from 'react';

function Settings({ settings, setSettings, show }) {

  const updateSetting = (name, value) => {
    axios
    .post(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/settings.json`, {setting: {[name]: value}})
  }

  const changeSetting = (name, value) => {
    let _settings = {...settings}
    _settings[name] = value
    setSettings(_settings)
  }

  return (
    <table className="table table-condensed table-striped table-sm" >
    <thead>
      <tr><th colSpan="2"><h1 align="center" className="whitebits">Octotrack Settings</h1></th></tr>
    </thead>
    <tbody>
      {settings && Object.entries(settings).map(([name, value], index) => (
        <tr key={`${index}`}>
          <td align="right">{name}</td>
          <td><input name={name} value={value} onChange={(e) => {changeSetting(e.target.name, e.target.value)}} onBlur={(e) => updateSetting(e.target.name, e.target.value)}/></td>
        </tr>
      ))}
    </tbody>
  </table>
)
}

export default Settings