import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community';

function Table({ insidePanel, gridOptions, rowData, ...props }) {
  // const [api, setApi] = useState(null);

  const onGridReady = (params) => {

    if (props.captureApi !== null) {
      props.captureApi(params.api);
    }

    if (props.sizeColumnsToFit) {
      params.api.sizeColumnsToFit();
    }

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      })
    })

  };

  return (
    <div>
      <div
        className={`${document.body.classList.value.includes('dark-mode') ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'} ${insidePanel ? "-mx-4" : ""} mb-1`}
      >
        <AgGridReact
          popupParent={document.querySelector("body")}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          rowData={rowData}
          domLayout={props.domLayout}
          enableCellTextSelection={props.enableCellTextSelection}
          pagination={props.pagination}
          defaultColDef={props.defaultColDef}
          isExternalFilterPresent={props.isExternalFilterPresent}
          doesExternalFilterPass={props.doesExternalFilterPass}
        />
      </div>
    </div>
  );
}

Table.defaultProps = {
  insidePanel: true,
  domLayout: "autoHeight",
  enableCellTextSelection: true,
  pagination: true,
  animateRows: true,
  defaultColDef: {
    flex: 1,
    suppressMovable: true,
    sortable: true,
    cellStyle: { fontSize: '12px' },
    enableCellChangeFlash: false,
    resizable: true,
    minWidth: 100,
    filter: true,
    wrapText: true,
    autoHeight: true,
    filterParams: {
      clearButton: true,
      buttons: ['reset'],
    },
  },
  sizeColumnsToFit: true,
  isExternalFilterPresent: false,
  doesExternalFilterPass: true,
  captureApi: null,
};

export default Table;
