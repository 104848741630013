import axios from "axios";
import { useState, useEffect } from "react";
import LoadingSpinner from "./components/Helpers/LoadingSpinner";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import Table from "./components/Table";
import { Spinner } from "react-bootstrap";


function Agreements() {
  const [agreements, setAgreements] = useState();
  const [syncStarted, setSyncStarted] = useState(false);


  const getAgreements = () => {
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/agreements.json`)
      .then(({ data }) => {
        setAgreements(data.agreements);
      })
      .catch((error) => console.error(error));
  }

  const syncAgreements = () => {
    setSyncStarted(true)
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/agreements/sync.json`)
      .then(({ data }) => {
        setAgreements(data.agreements);
        setSyncStarted(false)
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getAgreements();
  }, []);

  if (!agreements) { return (<LoadingSpinner />) }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Tariff Code",
        field: "tariff_code",
        sortable: true,
        minWidth: 150,
        maxWidth: 300,
      }, {
        headerName: "Standing Charge EXC VAT",
        field: "standing_charge_exc_vat"
      }, {
        headerName: "Standing Charge INC VAT",
        field: "standing_charge_inc_vat"
      }, {
        headerName: "Valid From",
        field: "valid_from",
        cellRenderer: function (params) {
          return (<Moment format={`Do MMM YYYY`} >{params.value}</Moment>)
        }
      }, {
        headerName: "Valid To",
        field: "valid_to",
        sort: "desc",
        cellRenderer: function (params) {
          return (<Moment format={`Do MMM YYYY`} >{params.value}</Moment>)
        }
      }
    ]
  }

  return (
    <>
    <Table
          gridOptions={gridOptions}
          rowData={agreements}
        />
      <Button disabled={syncStarted} onClick={() => syncAgreements()}>Sync{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button>
    </>
  );
}

export default Agreements;