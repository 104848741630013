import React, { useEffect, useState } from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';

function Navheader({settings, setSettings}) {
  const [toggle, setToggle] = useState();
  const [showModal, setShowModal] = useState(false);

  function toggler() { setToggle(!toggle) }

  const updateSetting = (name, value) => {
    axios
    .post(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/settings.json`, {setting: {[name]: value}})
  }

  const changeSetting = (name, value) => {
    let _settings = {...settings}
    _settings[name] = value
    setSettings(_settings)
  }

  function logout() {
    axios.delete(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/users/sign_out.json`)
        .then(() => {
            localStorage.removeItem("user");
            window.location = "/";
        })
        .catch((error) => console.error(error));
}


  return (
    <>
      <Navbar collapseOnSelect expand="sm" className="navbar-custom" variant="dark" onToggle={toggler}>
        <Container >
          <Navbar.Brand href="/">Octotrack</Navbar.Brand>
          <Navbar.Toggle aria-controls="General-menu" />
          <Navbar.Collapse id="General-menu">
            <Nav className="justify-content-end">
              <NavDropdown title="Info" id="collasible-nav-info">
                <NavDropdown.Item href="/agreements">Agreements</NavDropdown.Item>
                <NavDropdown.Item href="/products">Products</NavDropdown.Item>
                <NavDropdown.Item href="/standard_unit_rates">Standard Unit Rates</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/settings">Settings</Nav.Link>

            </Nav>
          </Navbar.Collapse>
          <Nav className="justify-content-end">
          <NavDropdown style={{ right: 0 }} title={JSON.parse(localStorage.getItem('user')).first_name} id="collasible-nav-help">
                            <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
                        </NavDropdown>
                      </Nav>
        </Container>
      </Navbar>
    </>
  );

}

export default Navheader;