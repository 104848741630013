import React, { useState } from "react";
import { Helmet } from "react-helmet";
import LoginForm from "./LoginForm";
import ToastComponent from "../components/ToastComponent";
import { Button, Row, Col } from "react-bootstrap";
import "./Login.css";

function Login({ setToken }) {
  const [hasForgotPassword, setHasForgotPassword] = useState(false);

  //document.body.style.backgroundColor = "green";
  return (
    <>
      <Helmet title="Octotrack | Log in" />
      <ToastComponent />

      <div className="main">
          <p className="sign" align="center">OctoTrack</p>

          {hasForgotPassword
            ? ''
            : <LoginForm setHasForgotPassword={setHasForgotPassword} />
          }

      </div>
    </>
  );
}

export default Login;
