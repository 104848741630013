
import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "./components/Helpers/LoadingSpinner";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { BarChart, Bar } from 'recharts';
import { Button, ButtonGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./components/Table";
import { Table as BSTable } from "react-bootstrap";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import Panel from "./components/Panel";
import { BsArrowRepeat } from 'react-icons/bs';
import Moment from 'react-moment';

function UsageDay({ settings }) {
  const [consumption, _setConsumption] = useState();
  const [usageStart, setUsageStart] = useState();

  const consumptionRef = React.createRef();


  const setConsumption = data => {
    consumptionRef.current = data
    _setConsumption(data)
  }

  const DayNightAll = {
    Day: 0,
    Night: 1,
    All: 2
  }
  const [filter, setFilter] = useState(DayNightAll.All);

  let { _startDate } = useParams();
  if (!_startDate) {
    _startDate = moment.utc(new Date() - 1).format("YYYY-MM-DD")
  }
  const [day, setDay] = useState(Date.parse(`${_startDate}`));
  function rounder(num) { return Math.round((num + Number.EPSILON) * 100) / 100 }

  const getConsumption = (_day) => {
    document.title = `OctoTrack Day - ${moment.utc(day).format("YYYY-MM-DD")}`
    window.history.replaceState(null, `OctoTrack Day - ${moment.utc(_day).format("YYYY-MM-DD")}`, `/usage/day/${moment.utc(_day).format("YYYY-MM-DD")}`)
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/usage/day.json${_day ? `?day=${_day}` : ''}`)
      .then(({ data }) => {
        if (!data.night_rates) {
          data.night_rates = {}
        }
        if (!data.day_rates) {
          data.day_rates = {}
        }
        setConsumption(data);
        setUsageStart(data.usage_start);
      })
      .catch((error) => console.error(error));
  }

  const adjust_toggle = (params) => {
    axios
    .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/consumption/${params.data.id}/adjust_toggle.json`)
    .then(({ data }) => {
      let _consumption = consumptionRef.current ? consumptionRef.current : consumption
      let _usages = [..._consumption.usages]
      let _ci = _usages.findIndex(c => c.id === params.data.id)
      _usages[_ci] = data
      _consumption.usages = _usages
      setConsumption(_consumption)
      getConsumption(moment.utc(day).format("YYYY-MM-DD"));
      params.api.refreshCells()
    })
    .catch((error) => console.error(error));
  }

  function filterConsupmtion(consumption, filter) {
    if (filter === DayNightAll.All) {
      return consumption
    }
    if (filter === DayNightAll.Day) {
      return consumption.filter(c => c.value_exc_vat > 12.0)
    }
    if (filter === DayNightAll.Night) {
      return consumption.filter(c => c.value_exc_vat < 12.0)
    }
  }

  function previousDate() {
    let _day = (new Date(day))
    _day = _day.setDate(_day.getDate() - 1)
    setDay(Date.parse(moment.utc(_day).format("YYYY-MM-DD")))
    getConsumption(moment.utc(_day).format("YYYY-MM-DD"))
  }

  function nextDate() {
    let _day = (new Date(day))
    _day = _day.setDate(_day.getDate() + 1)
    setDay(Date.parse(moment.utc(_day).format("YYYY-MM-DD")))
    getConsumption(moment.utc(_day).format("YYYY-MM-DD"))
  }

  useEffect(() => {
    getConsumption(moment.utc(day).format("YYYY-MM-DD"));
  }, []);

  if (!consumption) { return (<LoadingSpinner />) }

  let day_usage_total = 0
  let night_usage_total = 0

  let total_consumption = 0.0
  let night_count = 0
  let day_count = 0

  let total_export = 0.0
  let total_export_price = 0.0

  consumption.usages.map((u) => {
    total_consumption += u.consumption;
    total_export += u.export;
    total_export_price += u.export * (u.export_price/100);

    day_usage_total += u.value_exc_vat === consumption.day_rates.value_exc_vat ? u.consumption : 0.0
    day_count       += u.value_exc_vat === consumption.day_rates.value_exc_vat ? 1 : 0

    night_usage_total += u.value_exc_vat === consumption.night_rates.value_exc_vat ? u.consumption : 0.0
    night_count       += u.value_exc_vat === consumption.night_rates.value_exc_vat ? 1 : 0
  })

  let day_cost_total_exc_vat = day_usage_total * (consumption.day_rates.value_exc_vat / 100)
  let night_cost_total_exc_vat = 0.0
  if (consumption.night_rates.value_exc_vat) {
    night_cost_total_exc_vat = night_usage_total * (consumption.night_rates.value_exc_vat / 100)
  }
  let sub_total = day_cost_total_exc_vat + night_cost_total_exc_vat
  let standard_rate_cost = (consumption.day_rates.value_exc_vat / 100)

  const dayChargeRules = {
    "highUsage": params => (params.data.total_exc_vat) >= 1.0
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 48,
    columnDefs: [
      {
        headerName: "Action",
        field: "adjust",
        width: 10,
        cellRenderer: function (params) {
          return (<Button onClick={(e) => adjust_toggle(params)} size="sm" variant={params.value ? 'secondary' : 'primary'}>{params.value ? 'Y' : 'N'}</Button>)
        }

      }, {
        headerName: "Time",
        field: "interval_start",
        sortable: true,
      }, {
        headerName: "Consumption (kWh)",
        field: "consumption",
      }, {
        headerName: "Unit Price (exc VAT)",
        field: "value_exc_vat",
        cellRenderer: function (params) {
          return (<>{rounder(params.value)}p</>)
        }
      }, {
        headerName: "Cost (exc VAT)",
        field: "",
        cellClassRules: dayChargeRules,
        cellRenderer: function (params) {
          return (<>£{rounder((params.data.value_exc_vat / 100) * params.data.consumption)}</>)
        }
      }, {
        headerName: "Export",
        field: "export"
      }, {
        headerName: "Export Price",
        field: "export_price"
      }, {
        headerName: "Export Total", 
        field: "",
        cellRenderer: function (params) {
          return (<>£{rounder(params.data.export*(params.data.export_price / 100))}</>)
        }
      }

    ]
  }

  return (
    <div className="App">
      <h1>
        <Button onClick={() => previousDate()} disabled={day <= Date.parse(`${usageStart}`) ? true : false}> Previous</Button><> </>
        {moment.utc(day).format("DD MMM YYYY")}<> </>
        <Button onClick={() => nextDate()} disabled={day >= new Date() ? true : false}> Next </Button>
        <Button onClick={() => getConsumption(moment.utc(day).format("YYYY-MM-DD"))} variant="secondary"><BsArrowRepeat /></Button>
      </h1>
      <h2><ButtonGroup>
        <Button onClick={() => setFilter(DayNightAll.All)} disabled={filter === DayNightAll.All} variant={filter === DayNightAll.All ? 'secondary' : 'primary'}>All</Button>
        <Button onClick={() => setFilter(DayNightAll.Day)} disabled={filter === DayNightAll.Day} variant={filter === DayNightAll.Day ? 'secondary' : 'primary'}>Day</Button>
        <Button onClick={() => setFilter(DayNightAll.Night)} disabled={filter === DayNightAll.Night} variant={filter === DayNightAll.Night ? 'secondary' : 'primary'}>Night</Button>
      </ButtonGroup></h2>
      <Panel>
        <BarChart width={1200} height={250} data={filterConsupmtion(consumption.usages, filter)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="interval_start" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="consumption" fill="#8884d8" />
          <Bar dataKey="export" fill="red" />
        </BarChart>
        <BSTable striped bordered hover size="sm">
          <thead><tr>
            <th>{usageStart}</th>
            <th>Day Usage (kWh)</th>
            <th>Day Cost (exc VAT)</th>
            <th>Night Usage (kWh)</th>
            <th>Night Cost (exc VAT)</th>
            <th>Exported (kWh)</th>
            <th>Total Export</th>
            <th>Sub Total (exc VAT)</th>
            <th>Total (Standing Charge {rounder(consumption.product.standing_charge_inc_vat)}p)</th>
            
          </tr></thead>
          <tfoot>
            <tr>
              <td>Totals</td>
              <td>{rounder(day_usage_total)}</td>
              <td>£{rounder(day_cost_total_exc_vat)}</td>
              <td>{rounder(night_usage_total)}</td>
              <td>£{rounder(night_cost_total_exc_vat)}</td>
              <td>{rounder(total_export)}</td>
              <td>£{rounder(total_export_price)}</td>
              <td>£{rounder(sub_total)}</td>
              <td>£{rounder((sub_total * 1.05) + (consumption.product.standing_charge_inc_vat / 100))}</td>
              
            </tr>
            <tr>
              <td>Averages</td>
              <td>{rounder(day_usage_total / day_count)}</td>
              <td>£{rounder(day_cost_total_exc_vat / day_count)}</td>
              <td>{night_count === 0 ? 0 : rounder(night_usage_total / night_count)}</td>
              <td>£{night_count === 0 ? 0 : rounder(night_cost_total_exc_vat / night_count)}</td>
              <td></td>
              <td></td>
              <td>£{rounder(sub_total / consumption.usages.length)}</td>
              <td>£{rounder((sub_total + (consumption.product.standing_charge_inc_vat / 100)) / consumption.usages.length)}</td>
              
            </tr>
          </tfoot>

        </BSTable>
        <br />
        <BSTable striped bordered hover size="sm">
          <thead><tr>
            <th>{usageStart}</th>
            <th>Average p/kWh</th>
            <th>Total Usage ({rounder(total_consumption)}kWh) * Unit Price</th>
            <th>Total (Standing Charge {rounder(consumption.product.standing_charge_inc_vat)}p )</th>
            <th>Savings</th>
          </tr></thead>
          <tbody>
            <tr>
              <td>Standard Rate</td>
              <td>{rounder(standard_rate_cost * 100)}</td>
              <td>{rounder(total_consumption)}kWh * {rounder(standard_rate_cost)} = £{rounder(total_consumption * standard_rate_cost)}</td>
              <td>£{rounder((total_consumption * standard_rate_cost) * 1.05 + (consumption.product.standing_charge_inc_vat / 100))}</td>
              <td>£0</td>
            </tr>
            <tr>
              <td>Current Total</td>
              <td>{rounder(sub_total / total_consumption * 100)}</td>
              <td>{rounder(total_consumption)}kWh * {rounder(sub_total / total_consumption)} = £{rounder((sub_total))}</td>
              <td>£{rounder(sub_total * 1.05 + rounder(consumption.product.standing_charge_inc_vat / 100))}</td>
              <td>£{rounder((total_consumption * standard_rate_cost) - sub_total)}</td>
            </tr>
            <tr>
              <td>With Battery</td>
              <td>{consumption.night_rates && consumption.night_rates.value_exc_vat > 0 ? (consumption.night_rates.value_exc_vat) : (settings['nightRatePrice'])}</td>
              <td>{rounder(total_consumption)}kWh * {rounder(consumption.night_rates && consumption.night_rates.value_exc_vat > 0 ? (consumption.night_rates.value_exc_vat / 100) : settings['nightRatePrice'])} = £{rounder(total_consumption * (consumption.night_rates && consumption.night_rates.value_exc_vat > 0 ? (consumption.night_rates.value_exc_vat / 100) : (settings['nightRatePrice'] / 100)))}</td>
              <td>£{rounder(((total_consumption * (consumption.night_rates && consumption.night_rates.value_exc_vat > 0 ? (consumption.night_rates.value_exc_vat / 100) : (settings['nightRatePrice'] / 100))) * 1.05) + (consumption.product.standing_charge_inc_vat / 100))}</td>
              <td>£{rounder((total_consumption * standard_rate_cost) - (total_consumption * (consumption.night_rates ? (consumption.night_rates.value_exc_vat / 100) : (settings['nightRatePrice'] / 100))))}</td>
            </tr>
          </tbody>

        </BSTable>

        <Table
          gridOptions={gridOptions}
          rowData={filterConsupmtion(consumption.usages, filter)}
        />
      </Panel>
    </div>
  );
}

export default UsageDay;
