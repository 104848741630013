
import React, { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "./components/Helpers/LoadingSpinner";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area } from 'recharts';
import { BarChart, Bar } from 'recharts';
import { Button, Form, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from "./components/Table";
import { Table as BSTable } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment-timezone";
import Panel from "./components/Panel";
import { Spinner } from "react-bootstrap";
import { useHistory } from 'react-router-dom';


function Usage({ settings }) {
  const [consumption, setConsumption] = useState();
  let _startDate = new Date();
  _startDate.setDate(_startDate.getDate() - 30)
  const [startDate, setStartDate] = useState(_startDate);
  const [endDate, setEndDate] = useState(new Date());
  const [syncStarted, setSyncStarted] = useState(false);
  const [last_record, setLast_record] = useState();
  const [newData, setNewData] = useState(false);

  function rounder(num) { return Math.round((num + Number.EPSILON) * 100) / 100 }

  const syncConsumption = (all) => {
    setSyncStarted(true)
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/consumption/sync.json${all ? '?all=true' : ''}`)
      .catch((error) => console.error(error))
      .then(({ data }) => {
        if (data.last_record && last_record && data.last_record.interval_start > last_record.interval_start) {
          getConsumption()
          setNewData(true)
        }
        setLast_record(data.last_record)
        setSyncStarted(false)
      })
  }

  const recalculate = (params) => {
    let _day = null;
    if (params) {
      _day = params.data.day
    } 
    setSyncStarted(true)
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/consumption/recalculate.json`, {
        params: {
          date: _day
        }
      })
      .catch((error) => console.error(error))
      .then(({ data }) => {
        getConsumption()
        setNewData(true)
        setLast_record(data.last_record)
        setSyncStarted(false)
      })
    if (params) {
      params.api.refreshCells()
    }
  }


  const getConsumption = () => {
    axios
      .get(`${process.env.REACT_APP_API ? process.env.REACT_APP_API : window.location.origin}/api/v1/usage.json${startDate ? `?from=${startDate.toISOString().slice(0, 10)}&to=${endDate.toISOString().slice(0, 10)}` : ''}`)
      .then(({ data }) => {
        setConsumption(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const dayView = (day) => {
    window.location.href = `/usage/day/${day}`
  }

  useEffect(() => {
    getConsumption();
  }, []);

  if (!consumption) { return (<LoadingSpinner />) }

  let day_usage_total = 0
  let day_cost_total_exc_vat = 0.0
  let night_usage_total = 0
  let night_cost_total_exc_vat = 0.0
  let sub_total = 0.0
  let standing_charge_total = 0.0

  let export_total_kwh = 0.0
  let export_total_price = 0.0

  consumption.usage.map((u) => {
    day_usage_total += u.day_usage;
    day_cost_total_exc_vat += u.day_cost_exc_vat;
    night_usage_total += u.night_usage;
    night_cost_total_exc_vat += u.night_cost_exc_vat
    sub_total += (u.day_cost_exc_vat + u.night_cost_exc_vat)
    standing_charge_total += u.standing_charge_inc_vat
    export_total_kwh += u.export_usage
    export_total_price += u.export_cost_inc_vat
  })
  let consumption_total = day_usage_total + night_usage_total
  let standard_rate_cost = consumption_total * ((settings['standardRatePrice']) / 100)
  let standing_charge_in_pence_total = (standing_charge_total / 100)

  const cellBatterySize = {
    "highUsage": params => params.value >= settings['batterySize']
  }
  const dayChargeRules = {
    "highUsage": params => (params.value) >= settings['dayChargeWarning']
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 32,
    columnDefs: [
      {
        headerName: "Date",
        field: "day",
        sortable: true,
        sort: "desc",
        cellRenderer: function (params) {
          return (<a href={`/usage/day/${moment.utc(params.value).format("YYYY-MM-DD")}`}><Moment format={`Do MMM YYYY`} >{params.value}</Moment></a>)
        }
      }, {
        headerName: "Day Usage (kWh)",
        field: "day_usage",
        cellClassRules: cellBatterySize
      }, {
        headerName: "Day Cost (exc VAT)",
        field: "day_cost_exc_vat",
        cellRenderer: function (params) {
          return (<>£{params.value}</>)
        }
      }, {
        headerName: "Night Usage (kWh)",
        field: "night_usage",
      }, {
        headerName: "Night Cost (exc VAT)",
        field: "night_cost_exc_vat",
        cellRenderer: function (params) {
          return (<>£{params.value}</>)
        }
      }, {
        headerName: "Total kWh",
        field: "",
        cellRenderer: function (params) {
          return (<>{rounder(params.data.day_usage + params.data.night_usage)}</>)
        }
      }, {
        headerName: "p/kWh",
        field: "",
        cellRenderer: function (params) {
          return (<>{rounder((params.data.sub_total / (params.data.day_usage + params.data.night_usage)) * 100)}</>)
        }
      }, {
        headerName: "Export kWh",
        field: "export_usage",
        cellRenderer: function (params) {
          return (<>{rounder(params.value)}</>)
        }
      }, {
        headerName: "Export Total",
        field: "export_cost_inc_vat",
        cellRenderer: function (params) {
          return (<>£{rounder(params.value)}</>)
        }
      }, {
        headerName: "Sub Total (exc VAT)",
        field: "sub_total",
        cellRenderer: function (params) {
          return (<>£{rounder(params.value)}</>)
        }
      }, {
        headerName: `Total (Standing Charge ${rounder(consumption.standing_charge / 100)}p) - Exported`,
        field: "total",
        cellClassRules: dayChargeRules,
        cellRenderer: function (params) {
          return (<>£{rounder(params.value)}</>)
        }

      }, {
        headerName: "Action",
        cellRenderer: function (params) {
          return (<>
            <Button size="sm" variant="primary" onClick={() => recalculate(params)}>Update</Button>
          </>)
        }
      }

    ]
  }

  return (
    <div className="App">
      <Panel>
        <h1>Usage Summary</h1>
        <InputGroup>
          {(consumption && consumption.consumption_last && consumption.consumption_last.interval_start) ?
            <Button onClick={(e) => syncConsumption(false)} variant="warning" disabled={syncStarted}>Sync Consumption{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button>
            :
            <Button onClick={(e) => syncConsumption(true)} variant="danger" disabled={syncStarted}>Sync ALL Consumption{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button>
          }
          <Button onClick={(e) => recalculate(null)} variant="primary" disabled={syncStarted}>recalculate{syncStarted ? <><Spinner size="sm" animation="border" variant="light" /></> : ''}</Button>
        </InputGroup>
        {newData ? <>New Data</> : <></>}
        <InputGroup>
          <strong>From Date</strong> <Form.Control type="date" value={startDate.toISOString().slice(0, 10)} min={consumption.usage_start} onChange={(e) => setStartDate(new Date(e.target.value))} />
          <strong>To Date</strong> <Form.Control type="date" value={endDate.toISOString().slice(0, 10)} onChange={(e) => setEndDate(new Date(e.target.value))} />
          <Button onClick={(e) => getConsumption()}>Go</Button>
        </InputGroup>
        <BarChart width={1200} height={250} barGap={-20} data={consumption.usage} syncId="anyId" onClick={(e) => dayView(e.activeLabel)}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey="night_usage" name="Night" stackId="a" barSize={20} fill="#8884d8" />
          <Bar dataKey="day_usage" name="Day" stackId="a" barSize={20} fill="#82ca9d" />
          <Bar dataKey="export_usage" name="Export" barSize={20} stackId="b" fill="red" />

        </BarChart>
        <AreaChart width={1200} height={250} data={consumption.usage} syncId="anyId"
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="day" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="night_cost_exc_vat" name="Night" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="day_cost_exc_vat" name="Day" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
          <Area type="monotone" dataKey="sub_total" name=" Sub Total" stroke="red" fillOpacity={0} fill="red" />
        </AreaChart>

        <AreaChart width={1200} height={250} data={consumption.usage} syncId="anyId"
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="colorBattery" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="green" stopOpacity={0.8} />
              <stop offset="95%" stopColor="green" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="orange" stopOpacity={0.8} />
              <stop offset="95%" stopColor="orange" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="day" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="dayChargeWarning" name="Warning Level" stroke="red" fillOpacity={0} fill="red" />
          <Area type="monotone" dataKey="total" name="Total" stroke="orange" fillOpacity={1} fill="url(#colorTotal)" />
        </AreaChart>
        <BSTable striped bordered hover size="sm">
          <thead><tr>
            <th>Date</th>
            <th>Day Usage (kWh)</th>
            <th>Day Cost (exc VAT)</th>
            <th>Night Usage (kWh)</th>
            <th>Night Cost (exc VAT)</th>
            <th>Export (kWh)</th>
            <th>Export Earned</th>
            <th>Sub Total (exc VAT)</th>
            <th>Total (Standing Charge {rounder(consumption.standing_charge)}p)</th>
          </tr></thead>
          <tfoot>
            <tr>
              <td>Totals</td>
              <td>{rounder(day_usage_total)}</td>
              <td>£{rounder(day_cost_total_exc_vat)}</td>
              <td>{rounder(night_usage_total)}</td>
              <td>£{rounder(night_cost_total_exc_vat)}</td>
              <td>{rounder(export_total_kwh)}</td>
              <td>£{rounder(export_total_price)}</td>
              <td>£{rounder(sub_total)}</td>
              <td>£{rounder((sub_total * 1.05) + ((consumption.standing_charge / 100) * consumption.usage.length))}</td>
            </tr>
            <tr>
              <td>Averages</td>
              <td>{rounder(day_usage_total / consumption.usage.length)}</td>
              <td>£{rounder(day_cost_total_exc_vat / consumption.usage.length)}</td>
              <td>{rounder(night_usage_total / consumption.usage.length)}</td>
              <td>£{rounder(night_cost_total_exc_vat / consumption.usage.length)}</td>
              <td>{rounder(export_total_kwh / consumption.usage.length)}</td>
              <td>£{rounder(export_total_price / consumption.usage.length)}</td>
              <td>£{rounder(sub_total / consumption.usage.length)}</td>
              <td>£{rounder(((sub_total * 1.05) + ((consumption.standing_charge / 100) * consumption.usage.length) - export_total_price) / consumption.usage.length)}</td>
            </tr>
          </tfoot>

        </BSTable>
        <br />
        <BSTable striped bordered hover size="sm">
          <thead><tr>
            <th>Last {consumption.usage.length} days</th>
            <th>Average p/kWh</th>
            <th>Total Usage ({rounder(consumption_total)}kWh) * Unit Price</th>
            <th>Total (Standing Charge {rounder(consumption.standing_charge)}p * {consumption.usage.length} = £{rounder(standing_charge_in_pence_total)})</th>
            <th>Total - Earned (£{rounder(export_total_price)})</th>
            <th>Savings</th>
          </tr></thead>
          <tbody>
            <tr>
              <td>Standard Rate</td>
              <td>{settings['standardRatePrice']}</td>
              <td>{rounder(consumption_total)}kWh * {rounder(settings['standardRatePrice'] / 100)} = £{rounder(standard_rate_cost)}</td>
              <td>£{rounder((standard_rate_cost * 1.05) + standing_charge_in_pence_total)}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><strong>Current Total</strong></td>
              <td><strong>{rounder((sub_total / consumption_total) * 100)} ({rounder((((sub_total * 1.05) - export_total_price) / consumption_total) * 100)})</strong></td>
              <td><strong>{rounder(consumption_total)}kWh * {rounder(sub_total / consumption_total)} = £{rounder(sub_total)}</strong></td>
              <td><strong>£{rounder(sub_total * 1.05 + standing_charge_in_pence_total)}</strong></td>
              <td><strong>£{rounder((sub_total * 1.05 + standing_charge_in_pence_total) - export_total_price)}</strong></td>
              <td><strong>£{rounder(standard_rate_cost - sub_total)}</strong></td>
            </tr>
            <tr>
              <td>Month End Estimate</td>
              <td>{rounder(sub_total / consumption_total * 100)}</td>
              <td>~ {rounder((consumption_total / consumption.usage.length) * 30)}kWh * {rounder(sub_total / consumption_total)} = £{rounder(((consumption_total / consumption.usage.length) * 30) * (sub_total / consumption_total))}</td>
              <td>~ £{rounder((((consumption_total / consumption.usage.length) * 30) * (sub_total / consumption_total) * 1.05) + (consumption.standing_charge / 100) * 30)}</td>
              <td>~ £{rounder(((((consumption_total / consumption.usage.length) * 30) * (sub_total / consumption_total) * 1.05) + (consumption.standing_charge / 100) * 30) - ((export_total_price / consumption.usage.length) * 30))} (-£{rounder((export_total_price / consumption.usage.length) * 30)}) </td>
              <td>--</td>
            </tr>

          </tbody>

        </BSTable>


        <Table
          gridOptions={gridOptions}
          rowData={consumption.usage}
        />
        <br />
      </Panel>
    </div>
  );
}

export default Usage;
